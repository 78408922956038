.svg {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  path {
    transition: all 0.2s ease;
    fill: transparent;
    stroke: black;

    &.black-overlay {
      fill: #00000050;
    }
  }
}
